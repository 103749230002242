/* .App {
  text-align: center;
} */
html {
  height: 100%;
  /* scroll-behavior: smooth; */
}
.body {
  font-family: "Roboto Condensed", sans-serif !important;
  color: #2c2c2e;

  min-height: 100%;
}
.ac-logo-hero {
  width: 160px;
}
.ac-logo-hero > g > g > polygon {
  fill: white !important;
}
.client-snackbar {
  display: flex;
  align-items: center;
}
.ac-button {
  color: whitesmoke !important;
  background-color: #bfa766 !important;
  border-radius: 8px !important;
  font-weight: bold !important;
}
.ac-button:disabled {
  color: #faf6f6 !important;
  background-color: #797979 !important;
  border-radius: 8px !important;
  font-weight: bold !important;
  width: 155px;
}
.bgimg {
  /* background-color: black; */
  position: relative;
  height: 85vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  display: block;
}

.banner-img {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  position: relative;
  margin: 0;
  /* margin-left: auto;
  margin-right: auto; */
  padding: 0;

  /* z-index: 10; */
  /* justify-content: center;*/
}

.Headline-container > * {
  z-index: 10;
}

.ac-banner::after {
  content: "";
  height: 100%;
  width: 100%;
}

/* .banner-img::after {
  content: "";
  position: absolute;
  height: 80vh;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.5s ease;
  overflow: hidden;
  background-color: rgba(3, 3, 3, 0.75);
  z-index: 0;
} */

.Headline {
  /* top: 0;
  left: 0; */

  color: white;
  /* z-index: 10;
  font-size: 2.5em;
  font-weight: 400; */
  /* flex-flow: column wrap; */
  /* justify-content: center;
  align-items: flex-start; */
}
/* .Banner-Container {
  align-items: center;
} */
.Headline-container {
  position: absolute;
  /* margin-left: auto;
  margin-right: auto; */
  z-index: 10;
  /* width: 100%;
  height: 100%; */
  /* justify-content: center;
  align-items: flex-start; */
}
/* @media (min-width: 600px) {
  .contact-form {
    order: 1;
  }
} */
.MuiAppBar-root {
  z-index: 200;
}
a {
  cursor: pointer;
}
.animate-almost-visible {
  animation-name: anim_btt;
  animation-duration: 0.6s;
  opacity: 1;
}
.intial {
  opacity: 0;
}
@keyframes anim_ttb {
  0% {
    transform: translate3d(0, -100px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}
.ac-banner {
  position: relative;
}
.acbanner-container {
  background-image: url(./banner15.jpg);
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;

  margin: 18px 0px;
}
.ac-banner img {
  max-width: 100%;
  object-fit: contain;
}
.banner-text {
  position: relative;
  padding: 70px 26px;
}
.modal {
  z-index: 10000000 !important;
  /* position: fixed;
  top: 0;
  left: 0; */
}
.text-light {
  color: rgba(44, 44, 46, 0.8);
}
.backdrop {
  z-index: 10000000 !important;
}
.paper-color {
  background-color: #fbfbfb;
  /* background-color: #f5f5f5; */
}
.divider-break {
  margin: 28px 0px 36px 0px !important;
  width: 100px;
  height: 2px !important;
  background-color: #bfa766 !important;
}
.img-wrap {
  overflow: hidden;
  /* height: 0; */
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  display: block;
  z-index: 0;
  cursor: pointer;
}
.album-length {
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 1.2em;
  background-color: rgba(3, 3, 3, 0.651);
  text-align: right;
  padding: 0.6em;
  bottom: 0;
  right: 0;
  border-radius: 6px 0 6px 0;
}
.album-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 99;
  color: white;
  font-size: 1.3em;
  /* background-color: rgba(250, 234, 162, 0.26); */
  background-color: rgba(54, 54, 54, 0.301);

  /* opacity: 0; */
  bottom: 0;
  right: 0;
  border-radius: 6px 0 6px 0;
}
.img-wrap img {
  /* min-width: 100%; */
  /* min-height: 100%; */
  object-fit: cover;
  max-width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
/* .Portrait {
  position: relative;
  max-width: 100%;
} */
/* .Portrait::after {
  display: block;
  content: "";
  padding-bottom: 166.66%;
} */
/* .Landscape {
  position: relative;
  height: calc(352px / 1.43);
  max-width: 100%;
} */
/* .Landscape ::after {
  display: block;
  content: "";
  padding-top: 166.66%;
} */
.text-link span {
  font-weight: 700;
  font-size: 1.5em;
}
.App-logo {
  padding: 16px 8px 16px 8px;
  /* height: 40px; */
  width: 48px;

  pointer-events: none;
}
.App-logo a span {
  font-weight: 800;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
/* .img-wrap {
  position: relative;
} */

#root {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

.inaktiv-link {
  color: #959595;
}
.text-link {
  color: #959595;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
}
.MuiToolbar-root {
  justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.benefit-description {
  padding: 18px 0 18px 0px;
}
@media (min-width: 1280px) {
  .benefit-description {
    max-width: 31% !important;
  }
}
.portfolio-img-cover {
  padding-bottom: 32px;
  position: relative;
}
.portfolio-img-cover > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portfolio-img-cover::after {
  content: "";
  display: block;
  max-width: 100%;
  height: auto;
  padding-top: 52.6%;
}
@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1168px !important;
  }
}

@media (min-width: 1280px) {
  .portfolio-img-container-prev {
    max-width: 47% !important;
  }
}
.portfolio-title {
  font-size: 1.4993rem !important;
  text-align: center;
  padding-top: 36px;
  color: #2c2c2e !important;
}
.img-container {
  position: relative;
}
.img-container > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-container::after {
  content: "";
  display: block;
  padding-top: 80%;
}
.portfolio-img-container {
  cursor: pointer;

  position: relative;
}
.portfolio-img-container > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
/* .card-content-container {
  background-color: red;
  width: 100%;
 
  position: relative; 
} */
@media (min-width: 1280px) {
  .card-content-container {
    max-width: 32% !important;
  }
}
@media (max-width: 600px) {
  .card-content-container {
    padding-bottom: 32px !important;
  }
}
/* .card-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
} */
/* .card-container {
  height: 0;
  position: relative;
  padding-top: 75%;
}
.card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
} */
/* .card-container ::after {
  position: relative;
  content: "";
  display: block;
  padding-top: 75%;
} */
.portfolio-img-container::after {
  content: "";
  display: block;
  padding-top: 52.6%;
}
.service-description {
  padding-top: 72px;
  padding-bottom: 72px;
}
.section-end {
  padding-bottom: 54px;
}
.service-list {
  padding: 0px 0px 0px 18px;
}
.App-link {
  color: #61dafb;
}
.svg-style {
  width: 21% !important;
  /* height: 68px !important; */
  fill: #f47d20;
  /* fill: #ffb703; */
  /* fill: #f48c06; */
  /* fill: #ec7b1d; */
  padding: 0;
}
.service-svg {
  width: 100%;
  fill: white;
  padding: 22px;
}
.service-avatar {
  width: 110px !important;
  height: auto !important;
  /* background-color: #b54a31 !important; */
  background-color: #32c030 !important;
  /* background-color: #219ebc !important; */
}
.benefits-heading {
  margin: 27px 0 18px 0 !important;
  line-height: 1.5em !important;
  letter-spacing: 0em;
  font-size: 24px !important;
}
.section-heading {
  /* color: "#e6b31e"; */
  color: #bfa766;
  font-weight: bold !important;
}
.section-sub-heading {
  font-weight: 300 !important;
  color: #2c2c2e;
}
.servi-intro-name {
  font-weight: bold !important;
  padding: 32px 0 32px 0;
}
/* .svg-style > g {
  fill: #e6b31e;
} */
.rectangle {
  width: 100%;
  border-radius: 4px;
  background-color: #94784c;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
